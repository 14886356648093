import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import ClientsModule2 from "../components/Homepage2023Content/ClientsModule2"
import Feedback from "../components/Feedback"
import HubspotForm from "react-hubspot-form"
import Helmet from "react-helmet"

function CapterraPage({ location }) {
  const [vidWidth, setvidWidth] = useState("")

  const checkScreenWidth = () => {
    if (typeof window !== "undefined") {
      if (window.innerWidth <= 415) setvidWidth((90 * window.innerWidth) / 100)
      else if (window.innerWidth <= 768)
        setvidWidth((70 * window.innerWidth) / 100)
      else if (window.innerWidth <= 1466)
        setvidWidth((40 * window.innerWidth) / 100)
      else setvidWidth((30 * window.innerWidth) / 100)
    }
  }

  useEffect(() => {
    checkScreenWidth()
  }, [])

  return (
    <Layout location={location}>
      <>
        <Helmet>
          <title>Book a Demo | Capterra Platform</title>

          <meta
            name="description"
            content="Explore Vantage Circle's all-in-one Employee Engagement Platform"
          />
          <meta
            property="twitter:title"
            content="Book a Demo | Capterra Platform"
          />
          <meta name="robots" content="index" />

          <meta
            property="twitter:description"
            content="Explore Vantage Circle's all-in-one Employee Engagement Platform"
          />

          <meta property="og:type" content="website" />

          <meta
            property="og:url"
            content="https://www.vantagecircle.com/book-a-demo-capterra/"
          />

          <meta property="og:site_name" content="Vantage Circle" />
          <meta property="og:title" content="Book a Demo | Capterra Platform" />

          <meta
            property="og:description"
            content="Explore Vantage Circle's all-in-one Emplogit yee Engagement Platform"
          />
          <link
            rel="canonical"
            href="https://www.vantagecircle.com/book-a-demo-capterra/"
          ></link>
        </Helmet>
        <section
          class="w-full bg-purple-100 flex flex-col items-center   relative overflow-x-hidden py-0 lg:py-8 overflow-y-hidden px-3 lg:px-0 capterra-page"
          id="hero"
        >
          <div class="lg:flex max-w-7xl px-5 xl:px-1 2xl:px-5">
            <div class="card-1 flex flex-col justify-center w-full mt-5 xl:mt-0 lg:w-1/2 xl:w-2/3 2xl:w-10/12">
              <div class="text-3xl lg:text-5xl xl:text-7xl text-center lg:text-left text-gray-250 font-medium pt-5 mb-6 md:px-12 lg:px-0 xl:w-10/12">
                Give Your Employees the Best{" "}
                <span className="text-newOrange">Employee Experience</span> with
                Vantage Circle
              </div>
              <ul class="list-inside  text-gray-250 lato text-base lg:text-lg xl:text-xl md:px-5 xl:px-0 w-full lg:w-full my-0 md:my-10 text-left">
                <li class="py-3 flex space-x-5">
                  <span>
                    <img
                      decoding="async"
                      loading="lazy"
                      src="https://res.cloudinary.com/vantagecircle/image/upload/v1643802564/gatsbycms/uploads/2021/11/tick.png"
                      height="15"
                      width="25"
                    />
                  </span>{" "}
                  <span>Make recognition fun and impactful</span>{" "}
                </li>
                <li class="py-3 flex space-x-5">
                  <span>
                    <img
                      decoding="async"
                      loading="lazy"
                      src="https://res.cloudinary.com/vantagecircle/image/upload/v1643802564/gatsbycms/uploads/2021/11/tick.png"
                      height="15"
                      width="25"
                    />
                  </span>{" "}
                  <span>Automate & simplify your R&R program</span>
                </li>
                <li class="py-3 flex space-x-5">
                  <span>
                    <img
                      decoding="async"
                      loading="lazy"
                      src="https://res.cloudinary.com/vantagecircle/image/upload/v1643802564/gatsbycms/uploads/2021/11/tick.png"
                      height="15"
                      width="25"
                    />
                  </span>{" "}
                  <span>Keep your employees engaged and motivated</span>
                </li>
              </ul>
            </div>
            <div className="lg:w-1/2 xl:w-1/3 2xl:w-6/12 flex items-center">
              <div
                id="form"
                className="lighterDesignForm w-full mb-10 md:mb-0 mt-5 xl:mt-0 flex flex-col place-items-center rounded-lg  pl-6 pr-4 md:pr-2 py-10 bg-white h-full"
              >
                <h2 class="text-2xl lato text-gray-250 pt-5 mb-8 md:px-12 lg:px-0 lg:pr-3 text-center">
                  Start your journey towards fostering a dynamic culture of
                  appreciation
                </h2>
                {/* <p class="text-base mb-8 text-center mt-2">
                            Get a personalized walkthrough of Vantage Rewards from a friendly member of our team
                        </p> */}
                <div class="w-full flex justify-center items-center lato rewards team">
                  <HubspotForm
                    portalId="6153052"
                    formId="ec66f22d-e49b-48b2-8375-d47bbcdbdd0c"
                    onSubmit={() => console.log("Submit!")}
                    onReady={form => console.log("Form ready!")}
                    loading={<div>Loading...</div>}
                  />
                </div>
                <p class="text-sm text-center underline">
                  <a href="/privacy-policy/" className="text-gray-900">
                    Read our Privacy Policy
                  </a>
                </p>
                <script
                  charset="utf-8"
                  type="text/javascript"
                  src="//js.hsforms.net/forms/embed/v2.js"
                ></script>
              </div>
            </div>
          </div>
          <div className="py-8">
            <div className="text-2xl xl:text-4xl text-center">
              Proven Global Leaders in{" "}
              <span className="text-orange">
                Employee Recognition and Engagement
              </span>
            </div>
            <div className="mt-11 w-full flex justify-center">
              <picture>
                <source
                  srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_800/q_auto/gatsbycms/uploads/2023/10/award.png"
                  media="(max-width: 799px)"
                  width="800"
                />

                <source
                  srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_1000/q_auto/gatsbycms/uploads/2023/10/award.png"
                  media="(min-width: 800px)"
                  width="1000"
                />

                <img
                  src="https://res.cloudinary.com/vantagecircle/image/upload/w_800/q_auto/gatsbycms/uploads/2023/10/award.png"
                  alt="ratings"
                  width="800"
                  decoding="async"
                />
              </picture>
            </div>
          </div>
        </section>
        <section class="py-10 xl:py-16">
          <ClientsModule2 />
        </section>
        <section
          class="px-6 w-full flex flex-col place-items-center"
          id="features"
        >
          <div className="max-w-6xl my-5 md:my-10 w-full space-y-20 md:space-y-0">
            {/* first */}
            <div className="flex flex-col-reverse md:flex-row w-full space-y-8 space-y-reverse md:space-y-0  md:space-x-36">
              <div className="w-full md:w-1/2  flex md:justify-end items-center">
                <div className="text-2xl text-center md:text-right lg:text-5xl">
                  Comprehensive <br /> 360 Social{" "}
                  <span className="text-orange">
                    Employee <br />
                    Recognition
                  </span>
                </div>
              </div>
              <div className="w-full md:w-1/2 flex justify-center md:justify-start">
                <picture>
                  <source
                    srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_300/q_auto/gatsbycms/uploads/2023/07/Rewards-social-recognition.webp"
                    media="(max-width: 799px)"
                    width="300"
                  />

                  <source
                    srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_500/q_auto/gatsbycms/uploads/2023/07/Rewards-social-recognition.webp"
                    media="(min-width: 800px)"
                    width="500"
                  />

                  <img
                    src="https://res.cloudinary.com/vantagecircle/image/upload/w_800/q_auto/gatsbycms/uploads/2023/07/Rewards-social-recognition.webp"
                    alt="ratings"
                    width="800"
                    decoding="async"
                  />
                </picture>
              </div>
            </div>
            {/* 2nd */}
            <div className="flex flex-col-reverse  md:flex-row-reverse  w-full space-y-8 space-y-reverse md:space-y-0  md:space-x-36 md:space-x-reverse">
              <div className="w-full md:w-1/2  flex md:justify-start  items-center">
                <div className="text-2xl text-center md:text-left lg:text-5xl">
                  Amazon Integration <br /> for Rewards Access to
                  <br />
                  <span className="text-orange">10+ Million Products</span>
                  <br />
                  <div class="mt-5 sm:mt-6 md:mt-6 flex justify-center md:justify-start">
                    <div>
                      <a
                        class="text-white bg-newOrange py-4 px-5 xl:px-10 rounded-full mx-1 transition duration-500 ease-in-out transform hover:-translate-y-1 flex justify-center items-center text-base md:text-lg"
                        href="#form"
                      >
                        Book a Demo
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/2 flex justify-center md:justify-end">
                <picture>
                  <source
                    srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_300/q_auto/gatsbycms/uploads/2023/10/2.png"
                    media="(max-width: 799px)"
                    width="300"
                  />

                  <source
                    srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_500/q_auto/gatsbycms/uploads/2023/10/2.png"
                    media="(min-width: 800px)"
                    width="500"
                  />

                  <img
                    src="https://res.cloudinary.com/vantagecircle/image/upload/w_800/q_auto/gatsbycms/uploads/2023/10/2.png"
                    alt="ratings"
                    width="800"
                    decoding="async"
                  />
                </picture>
              </div>
            </div>
            {/* 3rd */}
            <div className="flex flex-col-reverse md:flex-row w-full space-y-8 space-y-reverse md:space-y-0  md:space-x-36">
              <div className="w-full md:w-1/2  flex md:justify-end items-center">
                <div className="text-2xl text-center md:text-right lg:text-5xl">
                  Global e-Gift Cards,
                  <span className="text-orange">Merchandise </span> & <br />
                  <span className="text-orange">Experiences</span>
                </div>
              </div>
              <div className="w-full md:w-1/2 flex justify-center md:justify-start">
                <picture>
                  <source
                    srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_300/q_auto/gatsbycms/uploads/2023/10/3.png"
                    media="(max-width: 799px)"
                    width="300"
                  />

                  <source
                    srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_500/q_auto/gatsbycms/uploads/2023/10/3.png"
                    media="(min-width: 800px)"
                    width="500"
                  />

                  <img
                    src="https://res.cloudinary.com/vantagecircle/image/upload/w_800/q_auto/gatsbycms/uploads/2023/10/3.png"
                    alt="ratings"
                    width="800"
                    decoding="async"
                  />
                </picture>
              </div>
            </div>
            {/* 4th */}
            <div className="flex flex-col-reverse  md:flex-row-reverse  w-full space-y-8 space-y-reverse md:space-y-0  md:space-x-36 md:space-x-reverse">
              <div className="w-full md:w-1/2  flex md:justify-start  items-center">
                <div className="text-2xl text-center md:text-left lg:text-5xl">
                  Direct Recognition on <br />
                  <span className="text-orange">Teams and Slack</span>
                  <br />
                  <div class="mt-5 sm:mt-6 md:mt-6 flex justify-center md:justify-start">
                    <div>
                      <a
                        class="text-white bg-newOrange py-4 px-5 xl:px-10 rounded-full mx-1 transition duration-500 ease-in-out transform hover:-translate-y-1 flex justify-center items-center text-base md:text-lg"
                        href="#form"
                      >
                        Book a Demo
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/2 flex justify-center md:justify-end">
                <picture>
                  <source
                    srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_300/q_auto/gatsbycms/uploads/2023/10/4.png"
                    media="(max-width: 799px)"
                    width="300"
                  />

                  <source
                    srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_500/q_auto/gatsbycms/uploads/2023/10/4.png"
                    media="(min-width: 800px)"
                    width="500"
                  />

                  <img
                    src="https://res.cloudinary.com/vantagecircle/image/upload/w_800/q_auto/gatsbycms/uploads/2023/10/4.png"
                    alt="ratings"
                    width="800"
                    decoding="async"
                  />
                </picture>
              </div>
            </div>
            {/* 5th */}
            <div className="flex flex-col-reverse md:flex-row w-full space-y-8 space-y-reverse md:space-y-0  md:space-x-36">
              <div className="w-full md:w-1/2  flex md:justify-end items-center">
                <div className="text-2xl text-center md:text-right lg:text-5xl">
                  Listening Tool for <br />
                  <span className="text-orange">Employee Opinion</span> <br />
                  and <span className="text-orange">Feedback</span>
                </div>
              </div>
              <div className="w-full md:w-1/2 flex justify-center md:justify-start">
                <picture>
                  <source
                    srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_300/q_auto/gatsbycms/uploads/2023/10/5.png"
                    media="(max-width: 799px)"
                    width="300"
                  />

                  <source
                    srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_500/q_auto/gatsbycms/uploads/2023/10/5.png"
                    media="(min-width: 800px)"
                    width="500"
                  />

                  <img
                    src="https://res.cloudinary.com/vantagecircle/image/upload/w_800/q_auto/gatsbycms/uploads/2023/10/5.png"
                    alt="ratings"
                    width="800"
                    decoding="async"
                  />
                </picture>
              </div>
            </div>
            {/* 6th */}
            <div className="flex flex-col-reverse  md:flex-row-reverse  w-full space-y-8 space-y-reverse md:space-y-0  md:space-x-36 md:space-x-reverse">
              <div className="w-full md:w-1/2  flex md:justify-start items-center">
                <div className="text-2xl text-orange text-center md:text-left lg:text-5xl">
                  Milestone Awards
                  <span className=" text-gray-900"> & </span> <br />
                  Service Yearbook
                  <br />
                  <div class="mt-5 sm:mt-6 md:mt-6 flex justify-center md:justify-start">
                    <div>
                      <a
                        class="text-white bg-newOrange py-4 px-5 xl:px-10 rounded-full mx-1 transition duration-500 ease-in-out transform hover:-translate-y-1 flex justify-center items-center text-base md:text-lg"
                        href="#form"
                      >
                        Book a Demo
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/2 flex justify-center md:justify-end">
                <picture>
                  <source
                    srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_300/q_auto/gatsbycms/uploads/2023/10/6.png"
                    media="(max-width: 799px)"
                    width="300"
                  />

                  <source
                    srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_500/q_auto/gatsbycms/uploads/2023/10/6.png"
                    media="(min-width: 800px)"
                    width="500"
                  />

                  <img
                    src="https://res.cloudinary.com/vantagecircle/image/upload/w_800/q_auto/gatsbycms/uploads/2023/10/6.png"
                    alt="ratings"
                    width="800"
                    decoding="async"
                  />
                </picture>
              </div>
            </div>
          </div>
        </section>
        <section
          class="w-full bg-purple-100 flex flex-col items-center   relative overflow-x-hidden py-8 overflow-y-hidden px-3 lg:px-0"
          id="FormVR"
        >
          <div className="max-w-7xl w-full">
            <div className="text-xl xl:text-4xl text-center mt-0 md:mt-8 ">
              What are our{" "}
              <span className="text-newOrange">customers saying</span> ?
            </div>
            <div className="max-w-5xl mx-auto flex flex-col md:flex-row items-center md:items-start md:justify-between my-10 space-y-6 md:space-y-0">
              <div className="w-3/5 md:w-1/4 p-8 flex flex-col items-center space-y-6 rounded-md shadow-lg bg-white transform transition duration-500 hover:scale-105">
                <div className="h-10">
                  <picture>
                    <img
                      src="https://res.cloudinary.com/vantagecircle/image/upload/w_180/q_auto/gatsbycms/uploads/2023/10/13.png"
                      alt="ratings"
                      decoding="async"
                    />
                  </picture>
                </div>
                <div className="text-center text-base">
                  saw an increase in <br />{" "}
                  <span className="text-newOrange">Appreciation</span> by
                </div>
                <div className="text-newOrange text-7xl">77%</div>
              </div>
              <div className="w-3/5 md:w-1/4 p-8 flex flex-col items-center space-y-6 rounded-md shadow-lg bg-white transform transition duration-500 hover:scale-105">
                <div className="h-10">
                  <picture>
                    <img
                      src="https://res.cloudinary.com/vantagecircle/image/upload/w_180/q_auto/gatsbycms/uploads/2023/10/12.png"
                      alt="ratings"
                      decoding="async"
                    />
                  </picture>
                </div>
                <div className="text-center text-base">
                  saw an increase in
                  <span className="text-newOrange">
                    {" "}
                    Peer-to-Peer Recognition
                  </span>{" "}
                  by
                </div>
                <div className="text-newOrange text-7xl"> {">"}82%</div>
              </div>
              <div className="w-3/5 md:w-1/4 p-8 flex flex-col items-center space-y-6 rounded-md shadow-lg bg-white transform transition duration-500 hover:scale-105">
                <div className="h-10">
                  <picture>
                    <img
                      src="https://res.cloudinary.com/vantagecircle/image/upload/w_180/q_auto/gatsbycms/uploads/2023/10/11.png"
                      alt="ratings"
                      decoding="async"
                    />
                  </picture>
                </div>
                <div className="text-center text-base">
                  saw increase in{" "}
                  <span className="text-newOrange">Employee Engagement</span> by
                </div>
                <div className="text-newOrange text-7xl">68%</div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-white feedback pt-10 xl:pt-20 relative">
          <div className="mx-auto lg:mx-w-5xl px-3" id="feedbackCarousel"></div>
        </section>
        <section
          class="w-full flex flex-col place-items-center py-10 xl:py-16 bg-purple-100 px-2 xl:px-0"
          id="casestudies"
        >
          <h2 class="lg:pr-3 w-full md:w-4/6 xl:max-w-5xl mx-auto homepage-heading font-bold text-center px-2">
            <span class="text-orange">All-in-one</span> suite
          </h2>
          <p class="text-gray-500 text-base md:text-lg xl:text-2xl py-3 text-center px-3">
            Our SaaS products are powerful alone but stronger together
          </p>
          <div class="grid md:grid-cols-2 xl:grid-cols-4 gap-6 justify-center items-center max-w-7xl mx-auto my-2 px-6 xl:px-2 2xl:px-6">
            <div class="rounded-xl transform hover:scale-105 transition duration-500 px-5 py-7 my-2 xl:my-5 shadow bg-white">
              <img
                decoding="async"
                loading="lazy"
                class="mx-auto pb-3"
                src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/06/Home-Rewards-Card.webp"
                alt="Celebrating employee achievements and recognising their contributions"
                width="300"
              />
              <h3 class="lato text-center">
                Employee Rewards and Recognition Platform
              </h3>
              {/* <div class="flex justify-center w-full mt-3"><a href="/products/rewards-recognition/" class="bg-newOrange transform hover:-translate-y-1 hover:shadow transition duration-500 px-6 py-3 mx-auto text-white rounded flex place-content-center w-full">Explore Vantage Rewards</a></div> */}
            </div>
            <div class="rounded-xl transform hover:scale-105 transition duration-500 px-5 py-7 my-2 xl:my-5 shadow bg-white">
              <img
                decoding="async"
                loading="lazy"
                class="mx-auto pb-3"
                src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/06/Home-Vfit-Card.webp"
                alt="Promoting employee well-being"
                width="300"
              />
              <h3 class="lato text-center">
                Employee Wellness <br /> Platform
              </h3>
              {/* <div class="flex justify-center w-full mt-3"><a href="https://www.vantagefit.io/" class="bg-newOrange transform hover:-translate-y-1 hover:shadow transition duration-500 px-6 py-3 mx-auto text-white rounded flex place-content-center w-full">Explore Vantage Fit</a></div> */}
            </div>
            <div class="rounded-xl transform hover:scale-105 transition duration-500 px-5 py-7 my-2 xl:my-5 shadow bg-white">
              <img
                decoding="async"
                loading="lazy"
                class="mx-auto pb-3"
                src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/06/Home-Pulse-Card.webp"
                alt="Gathering insights through employee surveys"
                width="300"
              />
              <h3 class="lato text-center">
                Employee Feedback <br /> Platform
              </h3>
              {/* <div class="flex justify-center w-full mt-3"><a href="/products/employee-survey/" class="bg-newOrange transform hover:-translate-y-1 hover:shadow transition duration-500 px-6 py-3 mx-auto text-white rounded flex place-content-center w-full">Explore Vantage Pulse</a></div> */}
            </div>
            <div class="rounded-xl transform hover:scale-105 transition duration-500 px-5 py-7 my-2 xl:my-5 shadow bg-white">
              <img
                decoding="async"
                loading="lazy"
                class="mx-auto pb-3"
                src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/06/Home-Perks-Card.webp"
                alt="Exclusive discounts and perks for employees"
                width="300"
              />
              <h3 class="lato text-center">
                Corporate Discounts <br /> Platform
              </h3>
              {/* <div class="flex justify-center w-full mt-3"><a href="/products/employee-discount/" class="bg-newOrange transform hover:-translate-y-1 hover:shadow transition duration-500 px-6 py-3 mx-auto text-white rounded flex place-content-center w-full">Explore Vantage Perks</a></div> */}
            </div>
          </div>
          <div class="mt-5 w-full sm:mt-6 md:mt-6 flex justify-center">
            <div>
              <a
                class="text-white bg-newOrange py-4 px-5 xl:px-10 rounded-full mx-1 transition duration-500 ease-in-out transform hover:-translate-y-1 flex justify-center items-center text-base md:text-lg"
                href="#form"
              >
                Book a Demo
              </a>
            </div>
          </div>
        </section>
        <section className="w-full bg-indigo-100 px-3 flex flex-col items-center justify-center py-6">
          <div className="xl:grid grid-cols-2 gap-10 justify-center items-center max-w-7xl mt-20 2xl:px-3">
            <div className="px-3 2xl:pl-5">
              <div className="flex justify-start w-full">
                <h2 className="text-3xl text-left md:text-center w-full xl:text-left md:text-4xl pb-8 xl:pb-3 text-purple-100">
                  Watch Video
                </h2>
              </div>
              <div className="py-3 rounded-md">
                <iframe
                  className="mx-auto xl:mx-0 rounded-md"
                  style={{ boxShadow: "1px 1px 15px rgba(0, 0, 0, 0.4)" }}
                  width={vidWidth}
                  height="300"
                  src="https://www.youtube.com/embed/_91XkfNchEA"
                  title="Vantage Rewards – Employee Rewards and Digital Gifting Platform | Employee Benefits"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
            <div className="px-3 xl:border-l border-white xl:pl-10 flex flex-col justify-start pt-10 xl:pt-0">
              <h2 className="text-3xl text-left md:text-center xl:text-left md:text-4xl text-purple-100 xl:pt-0 pt-5 xl:pb-3">
                Grow with us
              </h2>
              <div
                className="flex flex-col justify-center"
                style={{ height: "300px" }}
              >
                <p className="text-purple-100 text-xl md:text-2xl text-left md:text-center xl:text-left">
                  Join our community and deliver a satisfying employee
                  experience with our global employee engagement and wellness
                  platform.
                </p>
                <div className="flex pt-10 w-full">
                  <div className="w-5/12 xl:w-5/12 pr-2 xl:px-0 flex flex-col items-center xl:items-start">
                    <h2 className="text-4xl xl:text-6xl text-orange">10K+</h2>
                    <p className="text-purple-100">Reward Options</p>
                  </div>
                  <div className="w-1/2 xl:w-1/2 pl-3 xl:pl-10 border-l border-purple-100 flex flex-col items-center xl:items-start">
                    <h2 className="text-4xl xl:text-6xl text-orange">700+</h2>
                    <p className="text-purple-100">Clients</p>
                  </div>
                  <div className="w-1/2 xl:w-1/2 pl-3 xl:pl-10 border-l border-purple-100 flex flex-col items-center xl:items-start">
                    <h2 className="text-4xl xl:text-6xl text-orange">2M+</h2>
                    <p className="text-purple-100">Users</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </Layout>
  )
}

export default CapterraPage
